<template>
  <!-- 我的组织 -->
  <div class="myorg">
    <div class="myorg-left">
      <div class="searchform">
        <el-input v-filteremoji placeholder="请输入姓名" v-model="nameSearch" @keyup.enter.native="searchUser" class="searchinput"> </el-input>
        <el-button type="primary" @click="searchUser">搜索</el-button>
      </div>
      <el-tree class="filter-tree" :data="orgData" :props="defaultProps" ref="tree" node-key="orgId" :default-expanded-keys="defaultExpanded" @node-click="handleNodeClick"> </el-tree>
    </div>
    <div class="myorg-right">
      <ul v-if="orgUserList && orgUserList.length > 0">
        <li v-for="(item, index) in orgUserList" :key="index" @click="goUrl(item.userId)">
          <div class="avatar" v-if="item.avatar"><img :src="item.avatar ? item.avatar : defaultAvatar" /></div>
          <div class="avatar avatardefault" v-else>{{ item.userName.slice(-2) }}</div>
          <div class="username">{{ item.userName }}</div>
          <p>{{ item.orgChain }}</p>
          <p>{{ item.jobName }}</p>
        </li>
      </ul>
      <div v-else style="height:500px">
        <no-data :blankData="{ type: 'notdata', isShow: false }"></no-data>
      </div>
    </div>
  </div>
</template>
<script>
import { orgList, orgUserList, searchByName } from '@/api/api_public'
const defaultAvatar = require('@/assets/images/profile.png')
import noData from '@/components/business/blank/blank'
export default {
  name: 'personalCenter-organization',
  components: {
    noData
  },
  data() {
    return {
      defaultAvatar,
      orgData: [],
      defaultProps: {
        children: 'children',
        label: 'orgName'
      },
      orgUserList: [],
      nameSearch: '',
      defaultExpanded: []
    }
  },
  mounted() {
    this.getOrgData()
  },
  methods: {
    getOrgData() {
      orgList().then(result => {
        if (result && result.code === '000000') {
          this.orgData = result.data.treeList
          this.handleNodeClick(result.data.treeList[0])
          this.defaultExpanded = [ result.data.treeList[0].orgId ]
        }
      })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.orgName.indexOf(value) !== -1
    },
    handleNodeClick(data) {
      let params = {
        orgId: data.orgId
      }
      orgUserList(params).then(result => {
        if (result && result.code === '000000') {
          this.orgUserList = result.data.userList
        }
      })
    },
    goUrl(id) {
      this.$router.push({ path: '/homepage/navigation', query: { userId: id } })
    },
    searchUser() {
      if(!this.nameSearch) {
        this.$warning(`请先输入姓名`)
        return false;
      }
      let params = {
        name: this.nameSearch
      }
      searchByName(params).then(result => {
        if (result && result.code === '000000') {
          this.orgUserList = result.data.userList
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.myorg {
  width: calc(100% - 60px);
  height: calc(100% - 20px);
  display: flex;
  background: #fff;
  margin: 20px 30px 0 30px;
  &-left {
    width: 380px;
    padding: 20px;
    overflow: hidden;
    margin-right: 20px;
    height: 100%;
    overflow-y: scroll;
    /deep/.el-tree-node__label {
      @include text-overflow();
    }
    .searchform {
      margin-bottom: 20px;
      .searchinput {
        width: 256px;
        margin-right: 10px;
      }
    }
  }
  &-right {
    width: calc(100% - 400px);
    height: 100%;
    overflow-y: scroll;
    padding-top: 10px;
    ul {
      li {
        border-bottom: 1px solid $linecolor;
        padding: 15px 15px 15px 50px;
        position: relative;
        cursor: pointer;
        .avatar {
          width: 40px;
          height: 40px;
          position: absolute;
          left: 0;
          top: 15px;
          border-radius: 50%;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .avatardefault {
          background: $blue;
          line-height: 40px;
          color: #fff;
          font-size: 14px;
          text-align: center;
        }
        .username {
          font-size: 16px;
          font-weight: bold;
          color: $lightblack;
        }
        p {
          font-size: 12px;
          color: $grey;
          margin-top: 6px;
          line-height: 16px;
        }
      }
    }
  }
}
/deep/.el-tree-node__content {
  height: 30px;
}
</style>
